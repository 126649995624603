<div class="row h-100 align-items-center">
    <div class="col-xs-8 col-md-6">
        <h1><b>Kegel Trainer</b></h1>

        <p class="lead">Experience the benefits of Kegels and make them an effortless daily habit with the Kegel Trainer app.</p>

        <p class="lead">No matter where you start from, the pre-built levels and stages help you improve your strength so you can see results faster.</p>
        
        <ul class="lead">
          <li>Pre-nade stages & levels</li>
          <li>Fully customisable workouts</li>
          <li>Workout tracking</li>
          <li>Customisable reminders</li>
          <li>Discretion mode</li>
          <li>Leaderboards to compete with other users</li>
          <li>Much more</li>
        </ul>

        <p class="lead">The best part? <b>It's completely free and always will be.</b></p>
    
        <a href="https://apps.apple.com/us/app/kegel-trainer/id1451350209" target="_blank">
          <img src="../assets/app-store-button.svg" height="75px" width="150px">
        </a>
      </div>
      <div class="col-md-1"></div>
      <!-- <div class="col-xs-4 col-md-5">
        <img class="img-fluid float-right" src="assets/app-image.png">
    </div> -->
</div>