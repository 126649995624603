<div class="row h-100 align-items-center">
    <div class="col-12 pb-5 text-center align-items-center" style="word-break:break-all;">
        <a href="/">
            <img style="width:150px;height:150px;border-radius: 15px;" src="../../assets/kegel-trainer-logo.png">
        </a>
        <br><br>
        <h1>
            <a href="mailto:kegeltrainerapp@outlook.com">Contact support</a>
        </h1>
    </div>
</div>